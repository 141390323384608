<template>
  <div>
    <NavigationBar
      titel="Neues benutzerdefiniertes Feld anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <custom-field-editor
          v-model="customField"
          mode="new"
        ></custom-field-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { CREATE_CUSTOM_FIELD } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CustomFieldEditor from "@/components/member-management/custom-fields/CustomFieldEditor.vue";

export default {
  name: "member-management-custom-field-new",
  components: {
    NavigationBar,
    CustomFieldEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      customField: {},
    };
  },

  methods: {
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${CREATE_CUSTOM_FIELD}`, {
          organizationId: this.$route.params.organizationId,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
